import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import portable_toilet from "../../public/static/images/products/portable_toilet.jpg"

function PortableToilet() {

  const prodDetail = {
    "name": "PORTABLE TOILET / URINAL / MULTI STREET / BUNK HOUSE",
    "detail": {
      "about" : ["We Are Manufacturing A Wide Range of Fully Furnished Mobile Portable Toilets & Bio Toilets as per DRDO Norms That are Used to Providing The Public Facilities At Mega Fairs, Public Festivals, Exhibitions & Municipalities Use Etc.", "We are Being Manufactured With High Quality Materials, Reliability, Superior Finishing & Utilization Use."],
      "advantages": ["Eco-Friendly Portable Toilet. The offered toilet finds wide application use in the trade fair. Furthermore, the toilet is Manufactured using quality material and approved technologies. In addition, the toilet is made available to the clients at marginal rates."]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Seater",
        "Sheet Material",
        "Thickness",
        "Sludge Tank Capacity",
        "Water Tank Capacity",
        "Size",
        "Arrangement",
        "Facilities"
      ],
      "column2": [
        "IEPT / IEPTSS",
        "1,2,4 Seats or Customized",
        "M.S. Galvanized Iron, Stainless Steel, FRP Etc.",
        "1 mm, 1.6 mm, 2 mm, 2.5 mm, 3 mm or can be Customized as per requirement.",
        "500 ltres. or can be Customized as per requirement.",
        "100 ltres. or can be Customized as per requirement.",
        "920 x 975 x 2130 mm or can be Customized as per requirement.",
        "Provision for Both Men & Women with fully furnished Public service at fairs.",
        "Proper Ventilation, Exhaust Fans, Superior Finishing with facilities like Mirror, Soap, Tray Towel, Rod, Washbasin, Handle with Locks etc."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={portable_toilet} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default PortableToilet
